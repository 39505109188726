import store, { resetStore } from '@/store';
import { apiService } from './ApiService';
import {
  resetUserDetails,
  setIsUserAuthorized,
  setUserDetails,
} from '@/store/features/userSlice';
import { setCookie, deleteCookie, getCookie } from 'cookies-next';
import { clearCart } from '@/store/features/cartSlice';
import axios from 'axios';
import { googleLogout } from '@react-oauth/google';
const AUTO_LOGOUT_DURATION = 3 * 60 * 60 * 1000;

export const userService = {
  register,
  login,
  logout,
  checkOtp,
  loginWithOtp,
  verifyOtp,
  forgotWithEmail,
  verifyForgotOtp,
  loginWithSocial,
  checkTokenExpire,
  sendOtp,
};

async function register(formData: any) {
  const response = await apiService.post(`register`, formData);
  return response;
}

function setLoggedUserDetails(response: any) {
  localStorage.setItem('loginTime', new Date().toISOString());
  const pincode = response?.data?.pincode;
  if (pincode) {
    setCookie('pincode', pincode);
    setCookie('state', response?.data?.state);
    setCookie('city', response?.data?.city);
  }
  store.dispatch(setUserDetails(response.data));
  setCookie('woodenstreetUserAuthToken', response.data.token);
  setCookie('isUserAuthenticated', true);
  store.dispatch(setIsUserAuthorized(true));
  checkTokenExpire();
}

async function login(formData: any) {
  const response = await apiService.post(`login`, formData);
  if (response.code == 200) {
    setLoggedUserDetails(response);
  }
  return response;
}

async function sendOtp(formData: any) {
  const response = await apiService.post(`sendOtp`, formData);
  return response;
}

async function loginWithOtp(formData: any) {
  const response = await apiService.post(`loginWithOtp`, formData);
  return response;
}

async function forgotWithEmail(formData: any) {
  const response = await apiService.post(`forgotPassword`, formData);
  return response;
}

function logout() {
  delete axios.defaults.headers.common.Authorization;
  store.dispatch(clearCart());
  store.dispatch(resetUserDetails());
  store.dispatch(setIsUserAuthorized(false));
  deleteCookie('woodenstreetUserAuthToken');
  deleteCookie('isUserAuthenticated');
  localStorage.removeItem('cartLead');
  localStorage.removeItem('loginTime');
  localStorage.removeItem('customerDetails');
  googleLogout();
  if (typeof window !== 'undefined') {
    (window as any).FB?.getLoginStatus((response: any) => {
      if (response.status === 'connected') {
        (window as any).FB.logout();
      }
    });
  }
  // Router.push('/');
  window.location.replace('/');
  resetStore();
}

async function checkOtp(formData: any) {
  const response = await apiService.post(`checkOtp`, formData);
  if (response.code == 200) {
    setLoggedUserDetails(response);
  }
  return response;
}

async function verifyOtp(formData: any) {
  const response = await apiService.post(`verifyOtp`, formData);
  if (response.code == 200) {
    setLoggedUserDetails(response);
  }
  return response;
}

async function verifyForgotOtp(formData: any) {
  const response = await apiService.post(`verifyForgotPasswordOtp`, formData);
  return response;
}

async function loginWithSocial(formData: any) {
  const response = await apiService.post(`socialLogin`, formData);
  if (response.code == 200) {
    setLoggedUserDetails(response);
  }
  return response;
}

function checkTokenExpire() {
  const checkSession = () => {
    const loginTime = localStorage.getItem('loginTime');

    if (loginTime) {
      const elapsed = new Date().getTime() - new Date(loginTime).getTime();
      if (elapsed > AUTO_LOGOUT_DURATION) {
        userService.logout();
      }
    }
  };
  let intervalId: any = '';
  if (getCookie('isUserAuthenticated')) {
    intervalId = setInterval(checkSession, 60 * 1000);
  } else {
    clearInterval(intervalId);
  }
}
