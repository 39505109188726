import { apiService } from '@/services/ApiService';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state and its type
interface itemProps {
  category: any;
  products: any;
  topProducts: any;
}
interface CategoriesState {
  items: itemProps[];
  loading: boolean;
  error: string | null;
}

const initialState: CategoriesState = {
  items: [],
  loading: false,
  error: null,
};

// Async thunk to fetch categories products
export const fetchCategoriesProducts = createAsyncThunk(
  'categories/fetchCategoriesProducts',
  async (item: Omit<any, 'id'>, { rejectWithValue }) => {
    try {
      const response = await apiService.post('categories/products', item);
      return response.data; // API response
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch categories products'
      );
    }
  }
);

// Slice definition
const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesProducts.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors
      })
      .addCase(fetchCategoriesProducts.fulfilled, (state, action: any) => {
        state.loading = false;
        state.items = action.payload?.products || []; // Save fetched products
      })
      .addCase(fetchCategoriesProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string; // Save error message
      });
  },
});

// Selector with explicit return type
export const getCategoriesProducts = ({
  categories,
}: {
  categories: CategoriesState;
}): any[] => categories?.items;

export default categoriesSlice.reducer;
