import { getCookie } from 'cookies-next';
import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import store from '..';

// Define the shape of the cart state
interface CRMState {
  isMobile: boolean;
}

// Define the initial state
const initialState: CRMState = {
  isMobile: false,
};
export const addZendeskticket = createAsyncThunk(
  'createTicket',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const token = !getCookie('woodenstreetUserAuthToken');
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const res = await apiService.post(`zendesk/createTicket`, post, !token);
    return res;
  }
);
export const addRequestcallback = createAsyncThunk(
  // Store Page, Home Page Button
  'addRequestcallback',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(
      `crm/addrequestcallback`,
      post,
      !token
    );
    return response;
  }
);
export const addPriseDropLeads = createAsyncThunk(
  // Product Page
  'addPriseDropLeads',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path?.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(
      `crm/addPriseDropLeads`,
      post,
      !token
    );
    return response;
  }
);
export const addProductPinLeads = createAsyncThunk(
  // Product page pin (if pin not available)
  'addProductPinLeads',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(
      `crm/addProductPinLeads`,
      post,
      !token
    );
    return response;
  }
);
export const addSignupLead = createAsyncThunk(
  'addSignupLead',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(`crm/addSignupLead`, post, !token);
    return response;
  }
);
export const addSignupLeads = createAsyncThunk(
  'addSignupLeads',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(`crm/addSignupLeads`, post, !token);
    return response;
  }
);
export const addPincodeLead = createAsyncThunk(
  // Home page, Fast Delivery
  'addPincodeLead',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(`crm/addPincodeLead`, post, !token);
    return response;
  }
);
export const supportForm = createAsyncThunk(
  // Support form /support-form
  'supportForm',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(`crm/supportForm`, post, !token);
    return response;
  }
);
export const supportFormUnResolved = createAsyncThunk(
  // Support form /support-form
  'supportFormUnResolved',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(
      `crm/supportFormUnResolved`,
      post,
      !token
    );
    return response;
  }
);
export const crmOrderConfirm = createAsyncThunk(
  // Update Order
  'crmOrderConfirm',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(`crm/orderConfirm`, post, !token);
    return response;
  }
);
export const outOfStockLeads = createAsyncThunk(
  // Product-Details
  'outOfStockLeads',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(`crm/outOfStock`, post, !token);
    return response;
  }
);
export const furnitureExporters = createAsyncThunk(
  // Furniture-export
  'furnitureExporters',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(
      `crm/furnitureExporters`,
      post,
      !token
    );
    return response;
  }
);
export const sellOnWoodenstreet = createAsyncThunk(
  //sell-on-woodenstreet & footer
  'sellOnWoodenstreet',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(
      `crm/sellOnWoodenstreet`,
      post,
      !token
    );
    return response;
  }
);
export const addCartLead = createAsyncThunk(
  // add Cart
  'addCartLead',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(`crm/addCartLead`, post, !token);
    return response;
  }
);
export const storeVisit = createAsyncThunk(
  // product store Visit modal
  'storeVisit',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const path = window.location.href.split('http') ?? ' ';
    const pageSource = 'http' + path[path.length - 1];
    const post = { ...params, deviceType, pageSource };
    const response = await apiService.post(`crm/storeVisit`, post, !token);
    return response;
  }
);
export const setLeadsDetails = createAsyncThunk(
  // Update Lead
  'setLeadsDetails',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const response = await apiService.post(
      `crm/setLeadsDetails`,
      params,
      !token
    );
    return response;
  }
);
const crmSlice = createSlice({
  name: 'crm',
  initialState,
  reducers: {
    setMobileScreen: (state, action) => {
      state.isMobile = action.payload;
    },
  },
  extraReducers: () => {},
});
export const { setMobileScreen } = crmSlice.actions;
export const getMobileScreen = ({ common }: { common: any }) =>
  common?.isMobile;
export default crmSlice.reducer;
