import { getCookie } from 'cookies-next';
import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showLoginModal } from './loginSlice';

interface WishlistState {
  items: any[];
}

const initialState: WishlistState = {
  items: [],
};

export const getWishlist = createAsyncThunk(
  'wishlist/getWishlist',
  async () => {
    if (getCookie('woodenstreetUserAuthToken')) {
      const response: any = await apiService.get(
        `getWishlistProducts`,
        '',
        '',
        true
      );
      return response.data;
    } else return [];
  }
);

export const addWishlist = createAsyncThunk(
  'wishlist/addWishlist',
  async (data: any, { dispatch }) => {
    if (!getCookie('woodenstreetUserAuthToken')) {
      dispatch(showLoginModal());
      return { code: 505 };
    }
    const response: any = await apiService.post(
      `addWishlistProduct`,
      data,
      true
    );
    if (response.code === 200) dispatch(getWishlist());
    return response;
  }
);

export const removeWishlist = createAsyncThunk(
  'wishlist/removeWishlist',
  async (data: any, { dispatch }) => {
    if (!getCookie('woodenstreetUserAuthToken')) {
      dispatch(showLoginModal());
      return { code: 505 };
    }
    const response: any = await apiService.post(
      `removeWishlistProduct`,
      data,
      true
    );
    if (response.code === 200) dispatch(getWishlist());
    return response;
  }
);

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    // setWishlistDetails: (state, action) => {state.items = action.payload;},
  },
  extraReducers: (builder) => {
    builder.addCase(getWishlist.fulfilled, (state, action: any) => {
      state.items = action.payload.products || [];
    });
  },
});

// export const { setWishlistDetails } = wishlistSlice.actions;
export const getWishData = ({ wishlist }: { wishlist: any }) => wishlist?.items;
// export const getWishListById = ({ wishlist }: { wishlist: any }) => wishlist?.items?.map((x: any) => x.product_id) ?? [];

export default wishlistSlice.reducer;
