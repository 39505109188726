import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCookie, setCookie } from 'cookies-next';

// Define the shape of the cart state
interface CartState {
  items: any;
  userId?: string | null; // Optional userId for logged-in users
}

// Define the initial state
const initialState: CartState = {
  items: JSON.parse(getCookie('woodenstreetCartItems') || '[]'),
  userId: getCookie('woodenstreetCartUserId') ?? null,
};

export const getCartProducts = createAsyncThunk('getCartProducts', async () => {
  const token = getCookie('woodenstreetUserAuthToken');
  const woodenstreetCartItems = getCookie('woodenstreetCartItems');
  const response =
    woodenstreetCartItems &&
    (await apiService.post(`getAllProducts`, {}, !!token));
  return response;
});

export const addtoCart = createAsyncThunk(
  'addCartProduct',
  async (data: any) => {
    const token = getCookie('woodenstreetUserAuthToken');
    if (token) {
      const response: any = await apiService.post(`addCartProduct`, data, true);
      const fbqData = {
        type: 'track',
        event: 'AddToCart',
        data: {
          content_name: response.data.data.productname,
          content_category: response.data.data.category_name,
          content_ids: response.data.data.prid,
          content_type: '',
          value: response.data.data.discounted_price,
          currency: 'INR',
          brand_name: response.data.data.brand_name,
        },
      };

      if (typeof window.fbq === 'function') {
        window.fbq(fbqData.type, fbqData.event, fbqData.data);
      } else {
        console.log('fbq function is not available');
      }

      const smartechData = {
        type: 'dispatch',
        event: 'Add To Cart',
        data: response.data,
      };

      if (typeof window.smartech === 'function') {
        window.smartech('dispatch', smartechData.event, smartechData.data);
      } else {
        console.log('Smartech function is not available');
      }
      return response;
    }
  }
);

export const updateCart = createAsyncThunk('updateCart', async (data: any) => {
  const token = getCookie('woodenstreetUserAuthToken');
  if (token) {
    const response: any = await apiService.put(`updateCart`, '', data, true);
    return response;
  }
});

export const removeFromCart = createAsyncThunk(
  'removeCartProduct',
  async (data: any) => {
    const response: any = await apiService.post(
      `removeCartProduct`,
      data,
      true
    );
    return response;
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // Add item to cart
    addItem(state: CartState, action: PayloadAction<any>) {
      const item = state?.items?.find(
        (item: any) => item.product_id === action.payload.product_id
      );

      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.items.push(action.payload);
      }
      setCookie('woodenstreetCartItems', state.items);
    },
    updateQuantity(state: CartState, action: PayloadAction<any>) {
      const item = state.items.find(
        (item: any) => item.product_id === action.payload.product_id
      );
      if (item) {
        item.quantity = action.payload.quantity;
      }
      setCookie('woodenstreetCartItems', state.items);
    },
    // Remove item from cart
    removeItem(state: CartState, action: PayloadAction<number>) {
      state.items = state.items.filter(
        (item: any) => item.product_id !== action.payload
      );
      setCookie('woodenstreetCartItems', state.items);
    },
    // Clear cart
    clearCart(state: CartState) {
      state.items = [];
      setCookie('woodenstreetCartItems', state.items);
    },
    // Set user ID (for logged-in users)
    setUserId(state: CartState, action: PayloadAction<string>) {
      state.userId = action.payload;
      setCookie('woodenstreetCartUserId', state.userId);
    },
    // Clear user ID (for logged-out users)
    clearUserId(state: CartState) {
      state.userId = undefined;
      setCookie('woodenstreetCartUserId', state.userId);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCartProducts.fulfilled, (state, action: any) => {
      state.items = action.payload?.data?.data?.products?.map(
        (product: any) => {
          product.product_id = product.product_id ?? product.productId;
          return product;
        }
      );
      state.items && setCookie('woodenstreetCartItems', state.items);
    });
  },
});

export const {
  addItem,
  removeItem,
  clearCart,
  setUserId,
  clearUserId,
  updateQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
