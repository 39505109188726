// store/viewColumnSlice.ts
import { createSlice } from '@reduxjs/toolkit';

interface ViewColumnState {
  value: number;
  gridView: boolean;
}

const initialState: ViewColumnState = {
  value: 0, // Default value
  gridView: false,
};

const viewColumnSlice = createSlice({
  name: 'viewColumn',
  initialState,
  reducers: {
    setViewColumn: (state, action) => {
      state.value = action.payload;
    },
    setGridView: (state, action) => {
      state.gridView = action.payload;
    },
  },
});

export const { setViewColumn, setGridView } = viewColumnSlice.actions;

export const gridView = ({ viewColumn }: { viewColumn: any }) =>
  viewColumn.gridView;

export default viewColumnSlice.reducer;
