import { combineReducers } from '@reduxjs/toolkit';
import viewColumnReducer from './viewColumnSlice';
import customSetQuantitiesReducer from './customSetQuantitiesSlice';
import comboReducer from './comboSlice';
import loginModalReducer from './loginSlice';
import mobileMenuReducer from './mobileMenuSlice';
import mobileFilterReducer from './mobileFilterSlice';
import cartReducer from './cartSlice';
import galleryReducer from './gallerySlice';
import filterReducer from './filterSlice';
import userReducer from './userSlice';
import guestReducer from './guestSlice';
import wishlistReducer from './wishListsSlice';
import commonReducer from './commonSlice';
import storeLocation from './storeLocationSlice';
import orderReducer from './orderSlice';
import customerReducer from './customerSlice';
import ticketReducer from './ticketsSlice';
import categoriesReducer from './populerProductsSlice';
import customerAddressReducer from './customerAddressSlice';
import crmReducer from './crmSlice';

const rootReducer = combineReducers({
  viewColumn: viewColumnReducer,
  customSetQuantities: customSetQuantitiesReducer,
  combo: comboReducer,
  loginModal: loginModalReducer,
  mobileMenu: mobileMenuReducer,
  mobileFilter: mobileFilterReducer,
  cart: cartReducer,
  galleryModal: galleryReducer,
  filters: filterReducer,
  user: userReducer,
  guest: guestReducer,
  wishlist: wishlistReducer,
  common: commonReducer,
  stores: storeLocation,
  order: orderReducer,
  customer: customerReducer,
  ticket: ticketReducer,
  categories: categoriesReducer,
  customerAddress: customerAddressReducer,
  crm: crmReducer,
});

export default rootReducer;
