import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { getCookie } from 'cookies-next';

// Define the shape of the cart state
interface TicketState {
  tickets: any[];
  loading: boolean;
  ticketDetail: any[];
}

const initialState: TicketState = {
  tickets: [],
  loading: false,
  ticketDetail: [],
};

export const createTicket = createAsyncThunk(
  'createTicket',
  async (params: any) => {
    const response = await apiService.post(`createTicket`, params, true);
    return response;
  }
);

export const viewTicket = createAsyncThunk('viewTicket', async () => {
  const response = await apiService.get(`zendesk/viewTicket`, '', '', true);
  return response.data;
});

export const viewTicketDetails = createAsyncThunk(
  'viewTicketDetails',
  async (payload: any) => {
    const response = await apiService.post(
      `zendesk/viewTicketDetails`,
      payload,
      true
    );
    return response;
  }
);

export const addMessageToTicket = createAsyncThunk(
  'addMessageToTicket',
  async (payload: any) => {
    const response = await apiService.post(
      `zendesk/addMessageToTicket`,
      payload,
      true
    );
    return response;
  }
);

const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(viewTicket.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewTicket.fulfilled, (state: any, action) => {
        state.loading = false;
        state.tickets = action.payload;
      })
      .addCase(viewTicket.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(viewTicketDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewTicketDetails.fulfilled, (state: any, action) => {
        state.loading = false;
        state.ticketDetail = action.payload;
      })
      .addCase(viewTicketDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default ticketSlice.reducer;
