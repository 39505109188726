import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCookie } from 'cookies-next';

// Define the shape of the cart state
interface CommonState {
  requestedSettings: any;
  priceDetails: any;
  isFastOff: boolean;
  isFastChange: boolean;
  isFastUpdate: boolean;
  isWalletChecked: boolean;
}

// Define the initial state
const initialState: CommonState = {
  requestedSettings: {},
  priceDetails: {},
  isFastOff: false,
  isFastChange: false,
  isFastUpdate: false,
  isWalletChecked: true,
};

export const fetchStateAndCity = createAsyncThunk(
  'fetchStateAndCity',
  async (pincode: any) => {
    const payload = {
      pincode: pincode,
    };
    const response = await apiService.post(`getPincodeDetails`, payload);
    return response;
  }
);

export const fetchRequestedSettings = createAsyncThunk(
  'fetchRequestedSettings',
  async (params: string[]) => {
    const payload = {
      fieldValues: params,
    };
    const response = await apiService.post(
      `getRequestedSettingsValue`,
      payload
    );
    return response;
  }
);

export const fetchAllProducts = createAsyncThunk(
  'fetchAllProducts',
  async (params: any) => {
    const woodenstreetCartItems = getCookie('woodenstreetCartItems');
    if (woodenstreetCartItems) {
      const response = await apiService.post(`getAllProducts`, params, true);
      return response;
    } else {
      return {
        code: 200,
        message: 'Cart details retrieved successfully.',
        data: {
          data: {
            totalSave: 0,
            priceToBePaid: 0,
            products: [],
            totals: [],
            wallet: 0,
            addWallet: 0,
            couponMsg: '',
            count: 0,
            cartStatus: 1,
          },
          message: '',
        },
      };
    }
  }
);
export const getKitchenProduct = createAsyncThunk(
  'getKitchenProduct',
  async (params: any) => {
    const response = await apiService.get(`getKitchenProduct/${params}`);
    return response;
  }
);

export const isUserAlreadyPresent = createAsyncThunk(
  'isUserAlreadyPresent',
  async (params: any) => {
    const queryParams = new URLSearchParams({ identifier: params }).toString();
    const response = await apiService.get(
      `isUserAlreadyPresent?${queryParams}`
    );
    return response;
  }
);
export const checkPincode = createAsyncThunk(
  'checkPincode',
  async (params: any) => {
    const response = await apiService.post(`pinCheck`, params);
    return response;
  }
);
export const checkPin = createAsyncThunk('checkPin', async (params: any) => {
  const response = await apiService.get(
    `pincodeCheck?location=${params?.pincode}`
  );
  return response;
});
export const addFeedback = createAsyncThunk(
  'addFeedback',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    const response = await apiService.post(`addFeedback`, params, !token);
    return response;
  }
);
export const getWallet = createAsyncThunk('getWallet', async (page: number) => {
  const response = await apiService.get(
    `getWalletTransactions?page=${page}&limit=10`,
    '',
    '',
    true
  );
  return response;
});
export const getCustomerHelp = createAsyncThunk('getCustomerHelp', async () => {
  const response = await apiService.get(`getCustomerHelp`);
  return response;
});
export const getSearchProducts = createAsyncThunk(
  'getSearchProducts',
  async (params: any) => {
    const response = await apiService.post(`getSearchProducts`, params);
    return response;
  }
);
export const getExpressStock = createAsyncThunk(
  'getExpressStock',
  async (params: any) => {
    const response = await apiService.post(`getExpressStock`, params);
    return response;
  }
);

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setFastOnOff: (state, action) => {
      state.isFastOff = action.payload;
    },
    setFastChange: (state) => {
      state.isFastChange = !state.isFastChange;
    },
    setFastUpdate: (state) => {
      state.isFastUpdate = !state.isFastUpdate;
    },
    setWalletCheckUpdate: (state) => {
      state.isWalletChecked = !state.isWalletChecked;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRequestedSettings.fulfilled, (state, action: any) => {
      state.requestedSettings = action.payload?.data;
    });
    builder.addCase(fetchAllProducts.fulfilled, (state, action: any) => {
      state.priceDetails = action.payload?.data?.data;
    });
  },
});
export const {
  setFastOnOff,
  setFastChange,
  setFastUpdate,
  setWalletCheckUpdate,
} = commonSlice.actions;
export const getFastOnOff = ({ common }: { common: any }) => common?.isFastOff;
export const getFastChange = ({ common }: { common: any }) =>
  common?.isFastChange;
export const getFastUpdate = ({ common }: { common: any }) =>
  common?.isFastUpdate;
export const getRequestedSettings = ({ common }: { common: any }) =>
  common?.requestedSettings;
export const getAllProducts = ({ common }: { common: any }) =>
  common?.priceDetails;
export const getWalletChecked = ({ common }: { common: any }) =>
  common?.isWalletChecked;

export default commonSlice.reducer;
